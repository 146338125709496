import React from "react"
import { Accordion, Button, Card, Badge, Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Syllabus = () => (
  <React.Fragment>
  <Layout pageInfo={{ pageName: "Syllabus" }}>
    <SEO title="Syllabus" />
    <Container fluid>
      <Accordion>
        <Accordion.Item eventKey="035356212">
          <Accordion.Header>M.Ed.</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/M.Ed.Syllabus.pdf">
                  <Button variant="outline-dark">
                    Syllabus for M.Ed. <Badge bg="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
             
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="235462352">
          <Accordion.Header>B.Ed.</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/B.Ed.Syllabus.pdf">
                  <Button variant="outline-dark">
                    Syllabus for B.Ed. <Badge bg="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
            
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </Container>
  </Layout>
  </React.Fragment>
)

export default Syllabus
